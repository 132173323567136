import React from 'react';
import '../assets/style.css';  // Make sure this imports your CSS file
import { Link } from 'react-router-dom';
const Payprocess = () => {
  return (
    <div> 
      <h2 className="services-plan-title">Process to Pay</h2>

      <div
        className="container-success"
        style={{ width: '100%', maxWidth: 'none', padding: '0 20px' }}
      >
        
        <div className="success">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="succes-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div className="success-prompt-wrap">
            <p  className="success-prompt-heading"> first submit payment and then upload screenshot </p>
              <h5 className="success-prompt-heading">Easy Paisa</h5>
              <div className="success-prompt-prompt">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {/* Left Side */}
                  <div style={{ flex: 1, textAlign: 'left' }}>
                    <small>
                   ACCNAME 
                    </small>
                    <p  className="success-prompt-heading">Syed Mohsin Ali </p>
                    <small> ACC NUMBER:</small>
                    <p  className="success-prompt-heading">03030000000</p>
                  </div>

                  {/* Right Side */}
                  <div style={{ flex: 1, padding: '10px', textAlign: 'right', color:'red', fontSize:'20px', fontWeight:'bold', fontStyle:'italic' }}>
                    <p>
                     4000.00 Rs
                    </p>
                  </div>
                </div>
              </div>
             
               
                <small className='success-prompt-prompt'>Deposit Approve in just 5 Mins</small>
               
              
                    </div>
           
          </div>
       
         
          <form className="form" action="#">
          <small className="success-prompt-heading">Upload your payment Proof</small>
            <input
    placeholder="Upload File"
    id="payment-proof"
    name="paymentProof"
    type="file"
    className="input"
    accept="image/*,application/pdf"
    required
  />
   <small className="success-prompt-heading">Traxid </small>
     <input
             
              id="password"
              name="password"
              type= "number" // Toggle input type
              className="input"
              required
            />
 <input defaultValue="Sign In" type="submit" className="login-button" />
            </form>
        </div>


      </div>
    </div>
  );
};

export default Payprocess;
