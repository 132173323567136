import React from 'react'
import { Link } from 'react-router-dom'

const Rechargegateway = () => {
  return (
    <div>
      <div>

      <div
  style={{
    background: "#f8f9fd",
    backgroundImage: "linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%)",
    borderRadius: "40px",
    padding: "25px 35px",
    border: "5px solid rgb(255, 255, 255)",
    boxShadow: "rgba(143, 215, 133, 0.88) 0px 30px 30px -20px",
    margin: "20px"
  }}
>


<form className="form" action="#">
  {/* Add Money Input */}
  <input
    placeholder="Enter Amount (e.g., 1000)"
    id="add-money"
    name="addMoney"
    type="number"
    className="input"
    required
  />

  {/* Payment Gateway Dropdown */}
  <select
    id="payment-gateway"
    name="paymentGateway"
    className="input"
    required
  >
    <option value="">Select Payment Gateway</option>
    <option value="JazzCash">JazzCash</option>
    <option value="Easy Paisa">Easy Paisa</option>
    <option value="Bank Transfer">Bank Transfer</option>
  </select>

  {/* Submit Button */}
  <Link to="/payprocess">
  
  <input
    defaultValue="Add Money"
    type="submit"
    className="login-button"
  />
  </Link>
</form>

    
  </div>
</div>

    </div>
  )
}

export default Rechargegateway
