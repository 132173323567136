import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Nav, Collapse, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom"; // To handle active link highlighting

function Sidebar() {
  const [open, setOpen] = useState(false);
  const location = useLocation(); // Used to get the current path for active link styling

  // Function to close the menu after a link is clicked
  const handleLinkClick = () => {
    setOpen(false); // Close the menu
  };

  // Check if the link is active based on the current location
  const isActive = (path) => location.pathname === path;

  return (
    <div
      style={{
        backgroundColor: "#343a40", // Dark background for sidebar
        color: "#fff", // White text color
        minHeight: "100vh", // Full height of the viewport
        paddingTop: "50px", // Space for fixed navbar
        position: "fixed", // Sidebar is fixed on the left
        left: 0,
        top: 0,
        width: "250px",
      }}
    >
      <Navbar expand="lg" className="p-3 flex-column">
        {/* Admin Panel heading at the top */}
        <Navbar.Brand href="#" style={{ marginBottom: "30px", color: "#fff", fontSize: "20px", fontWeight: "bold" }}>
          Admin Panel
        </Navbar.Brand>

        {/* Hamburger button aligned to the top-left */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setOpen(!open)}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            borderColor: "#fff", // White border color for toggle
            backgroundColor: "#343a40",
            color: "#fff",
          }}
        />

        {/* Sidebar navigation links */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="flex-column">
            {["/admin/dashboard", "/admin/users", "/admin/settings"].map((path, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={path}
                onClick={handleLinkClick}
                style={{
                    width: "250px",
                  color: "#fff",
                  backgroundColor: isActive(path) ? "#rgb(253 105 4);" : "transparent",
                  transition: "background-color 0.3s ease",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.1)", // Add a border between links
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "rgba(0, 123, 255, 0.1)")}
                onMouseLeave={(e) => {
                  if (!isActive(path)) {
                    e.target.style.backgroundColor = "transparent";
                  }
                }}
              >
                {path.split("/admin/")[1].charAt(0).toUpperCase() + path.split("/admin/")[1].slice(1)}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Sidebar links visible when collapsed */}
      <Collapse in={open}>
        <div>
          <Nav className="flex-column p-3">
            {["/admin/dashboard", "/admin/users", "/admin/settings"].map((path, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={path}
                onClick={handleLinkClick}
                style={{
                  padding: "15px",
                  color: "#fff",
                  backgroundColor: isActive(path) ? "#007bff" : "transparent",
                  transition: "background-color 0.3s ease",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.1)", // Add a border between links
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "rgba(0, 123, 255, 0.1)")}
                onMouseLeave={(e) => {
                  if (!isActive(path)) {
                    e.target.style.backgroundColor = "transparent";
                  }
                }}
              >
                {path.split("/admin/")[1].charAt(0).toUpperCase() + path.split("/admin/")[1].slice(1)}
              </Nav.Link>
            ))}
          </Nav>
        </div>
      </Collapse>
    </div>
  );
}

export default Sidebar;
