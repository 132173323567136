import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

function Header() {
  return (
    <Navbar bg="green" variant="green" expand="lg" >
      <Container>
        {/* Left side: Welcome message */}
        <Navbar.Brand href="#" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Welcome Admin
        </Navbar.Brand>

        {/* Right side: Profile image with dropdown */}
        <Nav className="ml-auto">
          <NavDropdown
            title={
              <img
                src="https://via.placeholder.com/40" // Placeholder image for profile
                alt="Profile"
                className="rounded-circle"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  border: "2px solid #fff", // Adding a white border around the profile image
                }}
              />
            }
            id="navbar-dropdown"
            align="end"
          >
            <NavDropdown.Item href="#">Profile</NavDropdown.Item>
            <NavDropdown.Item href="#">Settings</NavDropdown.Item>
            <NavDropdown.Item href="#">Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;
