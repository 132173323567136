import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from './Home';
import Navbar from './Navbar';
import Withdraw from './Withdraw';

function Frontpage() {
  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home Route */}
      </Routes>
    </div>
  );
}

export default Frontpage;
