import React from "react";
import { Card, Row, Col } from "react-bootstrap";

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
      <Row>
        <Col sm={12} md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Card 1</Card.Title>
              <Card.Text>Quick example text for Card 1</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Card 2</Card.Title>
              <Card.Text>Quick example text for Card 2</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Card 3</Card.Title>
              <Card.Text>Quick example text for Card 3</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
