import React, { useState } from "react";
import "../assets/style.css";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false); // State for Password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for Confirm Password visibility

  // Toggles Password Visibility
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // Toggles Confirm Password Visibility
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <div className="register-container">
      <div >
        <div className="heading">Register</div>
        <form className="form" action="#">
          {/* Username Input */}
          <div className="input-container">
            <input
              placeholder="Username"
              id="username"
              name="username"
              type="text"
              className="input"
              required
            />
          </div>

          {/* Email Input */}
          <div className="input-container">
            <input
              placeholder="E-mail"
              id="email"
              name="email"
              type="email"
              className="input"
              required
            />
          </div>

          {/* Password Input */}
          <div className="input-container" style={{ position: "relative" }}>
            <input
              placeholder="Password"
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              className="input"
              required
            />
           <span
  style={{
    position: "absolute",
    right: "20px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    color: "#15d112", // Green color for both icons
  }}
  onClick={togglePasswordVisibility}
>
  {showPassword ?<FaEyeSlash style={{ color: "#15d112" }} /> : <FaEye style={{ color: "#15d112" }} />}
      </span>
          </div>

          {/* Confirm Password Input */}
          <div className="input-container" style={{ position: "relative" }}>
            <input
              placeholder="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              className="input"
              required
            />
            <span
              style={{
                position: "absolute",
                right: "20px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#15d112", // Green color
              }}
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ?<FaEyeSlash style={{ color: "#15d112" }} /> : <FaEye style={{ color: "#15d112" }} />}
                  </span>
          </div>

          {/* Submit Button */}
          <Link to="/home">
          <div className="input-container">
            <input defaultValue="Sign In" type="submit" className="login-button" />
          </div>
          </Link>
        </form>

        {/* Link to Login */}
        <div className="login-redirect">
          <Link to="/login" className="forgot-password">
            Already have an account?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
