import React, { useState } from "react";
import "../assets/style.css";
import { Link } from "react-router-dom";

const SupportTicket = () => {
  return (
    <div className="support-container">
      <div
        style={{
          background: "#f8f9fd",
          backgroundImage: "linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%)",
          borderRadius: "40px",
          padding: "25px 35px",
          border: "5px solid rgb(255, 255, 255)",
          boxShadow: "rgba(143, 215, 133, 0.88) 0px 30px 30px -20px",
          margin: "20px",
        }}
      >
        <div className="heading">Support Ticket</div>
        <form className="form" action="#">
          {/* Name Input */}
          <div className="input-container">
            <input
              placeholder="Name"
              id="name"
              name="name"
              type="text"
              className="input"
              required
            />
          </div>

          {/* Email Input */}
          <div className="input-container">
            <input
              placeholder="E-mail"
              id="email"
              name="email"
              type="email"
              className="input"
              required
            />
          </div>

          {/* Support Ticket Message Textarea */}
          <div className="input-container">
  <textarea
    placeholder="Write your message here..."
    id="message"
    name="message"
    className="input"
    rows="5"
    style={{
      resize: "none",
      padding: "10px",
     
      borderRadius: "5px",
    }}
    required
  />
</div>


         

          {/* Submit Button */}
          <div className="input-container">
            <input defaultValue="Submit" type="submit" className="login-button" />
          </div>
        </form>

        {/* Redirect to Another Page (Optional) */}
        <div className="login-redirect">
          <Link to="/" className="forgot-password">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;
