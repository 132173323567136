import React from 'react';
import { Link } from 'react-router-dom'; // Import Link component

const Navbar = () => {
  return (
    <div>
      <div className="mobile-bottom-navigation">
        {/* Link to Home Page */}
        <Link to="/home" className="action-btn">
          <ion-icon name="home"></ion-icon>
          <span className="icon-label">Home</span>
        </Link>

        {/* Link to Withdraw Page */}
        <Link to="/notification" className="action-btn">
          <ion-icon name="notifications" className="notification-icon"></ion-icon>
          <span className="icon-label">Notification</span>
        </Link>

        <Link to="/profile" className="action-btn">
          <ion-icon name="person" className="notification-icon"></ion-icon>
          <span className="icon-label">Profile</span>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
