import React from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Col, Image } from "react-bootstrap";
import '../assets/style.css'
import teams from '../assets/images/teams.png'

function Teams() {
  return (
    <div 
    className="container" 
    style={{ width: '100%', maxWidth: 'none', padding: '0 20px' }}
  >
      <h2 class="services-plan-title">Team</h2>

      <Row className="withdraw-box my-3 p-3">
  <Col xs={2} className="d-flex align-items-center justify-content-center">
    {/* Circular Image */}
    <Image
      src={teams}
      roundedCircle
      alt="User"
      className="withdraw-image"
    />
  </Col>
  <Col xs={7} className="d-flex flex-column justify-content-center">
    {/* Title and Data */}
    <h5 className="mb-1">Asad Ali</h5>
   
     
      <small className="text-success">Dec 20 12:45:am</small>
  
  </Col>
  <Col xs={3} className="d-flex align-items-center justify-content-end">
    {/* Amount */}
  
    
    <p className="text-muted mb-0">
      Invest Amount
      <br />
      <small className="text-success">Rs 4500.00</small>
    </p>
  </Col>
</Row>
<Row className="withdraw-box my-3 p-3">
  <Col xs={2} className="d-flex align-items-center justify-content-center">
    {/* Circular Image */}
    <Image
      src={teams}
      roundedCircle
      alt="User"
      className="withdraw-image"
    />
  </Col>
  <Col xs={7} className="d-flex flex-column justify-content-center">
    {/* Title and Data */}
    <h5 className="mb-1">Asad Ali</h5>
   
     
      <small className="text-success">Dec 20 12:45:am</small>
  
  </Col>
  <Col xs={3} className="d-flex align-items-center justify-content-end">
    {/* Amount */}
  
    
    <p className="text-muted mb-0">
      Invest Amount
      <br />
      <small className="text-success">Rs 65500.00</small>
    </p>
  </Col>
</Row>
     
    </div>
  );
}

export default Teams;
