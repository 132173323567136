import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LinkedPages from "./Components/Admin/Linkedpages"; // Admin Pages
import Frontpage from "./Components/Frontend/Frontpage"; // Frontpage with Home and Withdraw
import Withdraw from "./Components/Frontend/Withdraw";
import Navbar from "./Components/Frontend/Navbar";
import Recharge from "./Components/Frontend/Recharge";
import Invite from "./Components/Frontend/Invite";
import Teams from "./Components/Frontend/Teams";
import Register from "./Components/Frontend/Register";
import Rechargegateway from "./Components/Frontend/Rechargegateway";
import Login from "./Components/Frontend/Login";
import Payprocess from "./Components/Frontend/Payprocess";
import Myplans from "./Components/Frontend/Myplans";
import About from "./Components/Frontend/About";
import SupportTicket from "./Components/Frontend/SupportTicket";
import Notification from "./Components/Frontend/Notification";
import UpdateProfile from "./Components/Frontend/UpdateProfile";

function App() {
  return (
    <Router>
      {/* Define Routes for the main app */}
      <Routes>
        {/* Admin section, assuming it's nested inside /admin */}
        <Route path="/admin/*" element={<LinkedPages />} />

        {/* Frontend section with Navbar */}
        <Route
          path="/*"
          element={
            <>
              <Navbar />
              <Routes>
                <Route path="/home" element={<Frontpage />} />
                <Route path="/withdraw" element={<Withdraw />} />
                <Route path="/recharge" element={<Recharge />} />
                <Route path="/invite" element={<Invite />} />
                <Route path="/team" element={<Teams />} />
                <Route path="" element={<Register />} />
                <Route path="/addamount" element={<Rechargegateway />} />
                <Route path="/login" element={<Login />} />
                <Route path="/payprocess" element={<Payprocess />} />
                <Route path="/myplans" element={<Myplans />} />
                <Route path="/about" element={<About />} />
                <Route path="/support" element={<SupportTicket />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/profile" element={<UpdateProfile />} />

              </Routes>
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
