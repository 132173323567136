import React from 'react'
import '../assets/style.css'
const Myplans = () => {
  return (
    <div>
         <div 
  className="container" 
  style={{ width: '100%', maxWidth: 'none', padding: '0 20px' }}
>
<h2 class="services-plan-title">Plans History</h2>
<div class="plans-container">


<div class="plans-row">
  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title ">VIP 4</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 46700.00</p>
        <p><strong>Duration:</strong> 60 Days</p>
        <p><strong>Daily Profit:</strong> Rs 5000.00</p>
        <p><strong>Total Profit:</strong> Rs 600000.00</p>
      </div>
      <button class="purchase-plan-btn">Purchased</button>
    </div>
  </div>
  
  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 2</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1000.00</p>
        <p><strong>Duration:</strong> 120 Days</p>
        <p><strong>Daily Profit:</strong> Rs 100.00</p>
        <p><strong>Total Profit:</strong> Rs 6000.00</p>
      </div>
      <button class="purchase-plan-btn">Purchased</button>
    </div>
  </div>
  
  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="purchase-plan-btn">Purchased</button>
    </div>
  </div>
  

  
</div>

</div>
      </div>
    </div>
  )
}

export default Myplans
