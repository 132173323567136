import React, { useState } from 'react'; // Added useState hook for managing state
import { FaCopy } from 'react-icons/fa'; // Import the copy icon from react-icons
import '../assets/style.css';

const Invite = () => {
  const [isCopied, setIsCopied] = useState(false); // State for tracking copy action

  const handleCopy = () => {
    const copyText = document.getElementById("copyInput");
    copyText.select();
    document.execCommand("copy"); // Copy the text to clipboard
    setIsCopied(true); // Update state to show copied status

    // Reset the icon color after 2 seconds
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="invite-container">
      <div className="log-card">
        <p className="heading">Invite Code</p>
        
        <div className="input-group">
          <input className="input" type="text" placeholder="For Ex: Jayakrishna007" />
          <h6 className="info-text">Invite your friends who are interested in Earn Profit. You will get 3-level Commission.</h6>
          
          <p className="heading">Copy Link</p>
          
          <div className="copy-container">
            <input className="input" id="copyInput" type="text" value="https://yourlink.com" readOnly />
            <button
              className={`copy-button ${isCopied ? 'copied' : ''}`}
              onClick={handleCopy}
              style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', background: 'transparent', border: 'none', cursor: 'pointer' }}
            >
              <FaCopy size={14} style={{ color: isCopied ? 'green' : '#ccc' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invite;
