import React from "react";

function Users() {
  return (
    <div>
      <h2>Users</h2>
      <p>List of users will go here.</p>
    </div>
  );
}

export default Users;
