import React from 'react'
import background from '../assets/images/background.jpg'
import wallet from '../assets/images/wallet.png'
import teams from '../assets/images/teams.png'
import withdraw from '../assets/images/withdraw.png'
import invite from '../assets/images/invite.png'
import app from '../assets/images/app.png'

import plans from '../assets/images/plans.png'

import support from '../assets/images/support.png'

import about from '../assets/images/about.png'
import logout from '../assets/images/logout.png'
import '../assets/style.css'
import { Link } from 'react-router-dom'
const Home = () => {
    return (
        <div className="App">
      
            <main>
  
            <div class="category">
    
            <div 
  className="container" 
  style={{ width: '100%', maxWidth: 'none', padding: '0 20px' }}
>

  
    
                <div class="category-item-container ">
    
                
                <div class="showcase-banner">
                            <img src={background} class="showcase-img" />
                         
                            <div class="category-item">
  <div class="category-content-box">
    <div class="category-content-flex">
     
      <div class="category-left">
        <h1 class="category-title">Goldmine3x</h1>
        <div class="category-info">
          <h3 class="category-item-title">Syed Ali Shah</h3>
          <p class="category-balance">Balance: Rs:4893</p>
        </div>
      </div>
 <Link to="/addamount">
      <button class="invest-btn">Invest Now</button></Link>
      <div class="category-right">
      <h3 class="category-item-title">Deposit Wallet</h3>
          <p class="category-balance"> Rs: 4893</p>
      </div>
    </div>
  </div>
</div>


                  
                  </div>
                </div>
    
              </div>
    
            </div>
    

            <div class="product-container">
    
            
  <div className="container" 
  style={{ width: '100%', maxWidth: 'none', padding: '0 20px' }}
>
    <div class="product-box">
                  <div class="product-main">
                  <div class="product-grid">
      <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
      <Link to="/recharge">
        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={wallet} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
              fontSize: '16px', 
              color: '#555', 
              marginTop: '5px',
              fontWeight:'bold'
            }}
          >
            Recharge
          </span>
        </a>
        </Link>
      </div>
    </div>
    <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
 <Link to="/withdraw">
        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={withdraw} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
                fontSize: '16px', 
                color: '#555', 
                marginTop: '5px',
                fontWeight:'bold'
            }}
          >
            Withdraw
          </span>
        </a>
        </Link>
      </div>
    </div>   <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
<Link to="/team">
        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={teams} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
                fontSize: '16px', 
                color: '#555', 
                marginTop: '5px',
                fontWeight:'bold'
            }}
          >
            Teams
          </span>
        </a>
        </Link>
      </div>
    </div>
                    
                    </div>

<h2 class="services-plan-title">Our Services Plan</h2>
<br></br><br></br>
     <div class="product-grid">
    
                      
     <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      

        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={plans} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         <Link to="/myplans">
          <span 
            className="icon-label" 
            style={{
              fontSize: '16px', 
              color: '#555', 
              marginTop: '5px',
              fontWeight:'bold'
            }}
          >
            My Plans
          </span>
          </Link>
        </a>
      </div>
    </div>
                           
     <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
      <Link to="/invite">
        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={invite} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
              fontSize: '16px', 
              color: '#555', 
              marginTop: '5px',
              fontWeight:'bold'
            }}
          >
            Invite
          </span>
        </a>
        </Link>
      </div>
    </div>                   
     <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
<Link to="/about">
        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={about} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
              fontSize: '16px', 
              color: '#555', 
              marginTop: '5px',
              fontWeight:'bold'
            }}
          >
About          </span>
        </a>
        </Link>
      </div>
    </div>                   
     <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
<Link to="/support">
        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={support} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
              fontSize: '16px', 
              color: '#555', 
              marginTop: '5px',
              fontWeight:'bold'
            }}
          >
            Support
          </span>
        </a>
        </Link>
      </div>
    </div>                   
     <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      

        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={app} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
              fontSize: '16px', 
              color: '#555', 
              marginTop: '5px',
              fontWeight:'bold'
            }}
          >
            App
          </span>
        </a>
      </div>
    </div>                   
     <div className="showcase" 
      style={{
    
        border: '1px solid #ddd', 
        padding: '10px', 
        borderRadius: '10px', 
        textAlign: 'center'
      }}
    >
      <div className="showcase-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      

        <a href="#" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none',  }}>
            <img src={logout} className='img' style={{
              fontSize: '40px', // Increase icon size
              color: '#007bff'
            }}/>
         
          <span 
            className="icon-label" 
            style={{
              fontSize: '16px', 
              color: '#555', 
              marginTop: '5px',
              fontWeight:'bold'
            }}
          >
Logout          </span>
        </a>
      </div>
    </div>

               
                    </div> 
<br></br>
                    <h2 class="services-plan-title">Investment Plans</h2>
 <br></br> 
                    <div class="plans-container">


<div class="plans-row">
  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title ">VIP 1</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 600.00</p>
        <p><strong>Duration:</strong> 60 Days</p>
        <p><strong>Daily Profit:</strong> Rs 50.00</p>
        <p><strong>Total Profit:</strong> Rs 3000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div>
  
  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 2</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1000.00</p>
        <p><strong>Duration:</strong> 120 Days</p>
        <p><strong>Daily Profit:</strong> Rs 100.00</p>
        <p><strong>Total Profit:</strong> Rs 6000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div>
  
  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div>
  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div> <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div> <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div>

  <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div> <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div> <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div> <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div> <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div> <div class="plan-card">
    <div class="plan-details">
      <h4 class="plan-title">VIP 3</h4>
      <div class="plan-info">
        <p><strong>Price:</strong> Rs 1500.00</p>
        <p><strong>Duration:</strong> 180 Days</p>
        <p><strong>Daily Profit:</strong> Rs 150.00</p>
        <p><strong>Total Profit:</strong> Rs 9000.00</p>
      </div>
      <button class="buy-plan-btn">Buy Plan</button>
    </div>
  </div>
</div>

</div>
                  </div>
    
                </div>
    
              </div>
             
            </div>
          <div>
    
             </div>
  
            </main> 
         
        </div>
      );
}

export default Home
