import React from 'react';
import '../assets/style.css'; // Ensure your CSS is linked

const About = () => {
  return (
    
    <div className="about-page">
      {/* Header Section */}
      <div className="about-header">
        <h1>About Goldmine3x.com</h1>
        <p>Your trusted partner in smart investments</p>
      </div>

      {/* About Content */}
      <div className="about-content">
        <section className="about-section">
          <h2>Who We Are</h2>
          <p>
            Goldmine3x.com is an innovative investment platform designed to help
            you grow your wealth. With a focus on transparency, security, and
            high returns, we empower investors to achieve their financial goals.
          </p>
        </section>

        <section className="about-section">
          <h2>Our Mission</h2>
          <p>
            To provide accessible and secure investment opportunities to
            individuals and businesses globally. We aim to deliver consistent
            growth while maintaining the highest standards of integrity.
          </p>
        </section>

        <section className="about-section">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>💼 Expert financial management team</li>
            <li>🔒 Advanced security measures to protect your investments</li>
            <li>📈 Competitive returns tailored to your needs</li>
            <li>🌐 Global reach with a local touch</li>
          </ul>
        </section>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>What is Goldmine3x.com?</h3>
          <p>
            Goldmine3x.com is an investment platform that allows users to grow
            their wealth through secure and transparent investment plans.
          </p>
        </div>
        <div className="faq-item">
          <h3>How secure is my investment?</h3>
          <p>
            We employ state-of-the-art encryption and secure protocols to
            ensure that your investments are safe at all times.
          </p>
        </div>
        <div className="faq-item">
          <h3>What kind of returns can I expect?</h3>
          <p>
            Our platform offers competitive and consistent returns that vary
            depending on the selected investment plan.
          </p>
        </div>
      </div>

      {/* Footer Section */}
      <div className="about-footer">
        <p>
          Ready to take the next step in securing your financial future? 
          <a href="/signup" className="call-to-action">Join Us Today</a>
        </p>
      </div>
    </div>
  );
};

export default About;
