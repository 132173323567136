import React from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Col, Image } from "react-bootstrap";
import '../assets/style.css'
import withdraw from '../assets/images/withdraw.png'

function Withdraw() {
  return (
    <div 
    className="container" 
    style={{ width: '100%', maxWidth: 'none', padding: '0 20px' }}
  >
      <h2 class="services-plan-title">Withdraw</h2>

      <Row className="withdraw-box my-3 p-3">
  <Col xs={2} className="d-flex align-items-center justify-content-center">
    {/* Circular Image */}
    <Image
      src={withdraw}
      roundedCircle
      alt="User"
      className="withdraw-image"
    />
  </Col>
  <Col xs={7} className="d-flex flex-column justify-content-center">
    {/* Title and Data */}
    <h5 className="mb-1">Withdraw Title</h5>
    <p className="text-muted mb-0">
      Withdraw details or date
      <br />
      <small className="text-success">Approved</small>
    </p>
  </Col>
  <Col xs={3} className="d-flex align-items-center justify-content-end">
    {/* Amount */}
    <h5 className="text-success">Rs 34500.00</h5>
  </Col>
</Row>

     
    </div>
  );
}

export default Withdraw;
