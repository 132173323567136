import React from "react";
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./Sidebar"; // Your Sidebar Component
import Header from "./Header"; // Your Header Component
import Dashboard from "./Dashboard"; // Your Dashboard Component
import Users from "./Users"; // Your Users Component
import Setting from "./Setting"; // Your Settings Component

function LinkedPages() {
  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <Header />
          {/* Define Routes for admin pages */}
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Setting />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

export default LinkedPages;
