import React from 'react';
import '../assets/style.css';  // Make sure this imports your CSS file
import { Link } from 'react-router-dom';
const Recharge = () => {
  return (
    <div> 
      <h2 className="services-plan-title">Recharge History</h2>

      <div
        className="container-success"
        style={{ width: '50%', maxWidth: 'none', padding: '0 20px' }}
      >
        <div className="success">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="succes-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div className="success-prompt-wrap">
              <p className="success-prompt-heading">Recharge Approved</p>
              <div className="success-prompt-prompt">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {/* Left Side */}
                  <div style={{ flex: 1, textAlign: 'left' }}>
                    <p>
                     Recharge in Jazz Cash  
                    </p>
                    <h5> 6 days ago</h5>
                    <small> Dec 12 2024 23:48:pm</small>
                  </div>

                  {/* Right Side */}
                  <div style={{ flex: 1, padding: '10px', textAlign: 'right', color:'red', fontSize:'20px', fontWeight:'bold', fontStyle:'italic' }}>
                    <p>
                     4000.00 Rs
                    </p>
                  </div>
                </div>
              </div>
              <div className="success-button-container">
                <button type="button" className="success-button-main">
                  View status
                </button>
                <Link to="/addamount">
                <button type="button" className="success-button-main">
                  Recharge
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recharge;
