import React, { useState } from "react";
import "../assets/style.css";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash, FaUserCircle } from "react-icons/fa";

const UpdateProfile = () => {
  const [showPassword, setShowPassword] = useState(false); // State for Password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for Confirm Password visibility
  const [selectedImage, setSelectedImage] = useState(null); // State for Image Upload

  // Toggles Password Visibility
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // Toggles Confirm Password Visibility
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  // Handle Image Upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  return (
    <div className="register-container">
      <div
        style={{
          background: "#f8f9fd",
          backgroundImage: "linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%)",
          borderRadius: "40px",
          padding: "25px 35px",
          border: "5px solid rgb(255, 255, 255)",
          boxShadow: "rgba(143, 215, 133, 0.88) 0px 30px 30px -20px",
          margin: "20px",
        }}
      >
        <div className="heading">Update Your Profile</div>
        <form className="form" action="#">
          {/* Username Input */}
          <div className="input-container">
            <input
              placeholder="Username"
              id="username"
              name="username"
              type="text"
              className="input"
              required
            />
          </div>

          {/* Email Input */}
          <div className="input-container">
            <input
              placeholder="E-mail"
              id="email"
              name="email"
              type="email"
              className="input"
              required
            />
          </div>

          {/* Password Input */}
          <div className="input-container" style={{ position: "relative" }}>
            <input
              placeholder="Password"
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              className="input"
              required
            />
            <span
              style={{
                position: "absolute",
                right: "20px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#15d112",
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <FaEyeSlash style={{ color: "#15d112" }} />
              ) : (
                <FaEye style={{ color: "#15d112" }} />
              )}
            </span>
          </div>

          {/* Confirm Password Input */}
          <div className="input-container" style={{ position: "relative" }}>
            <input
              placeholder="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              className="input"
              required
            />
            <span
              style={{
                position: "absolute",
                right: "20px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#15d112",
              }}
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? (
                <FaEyeSlash style={{ color: "#15d112" }} />
              ) : (
                <FaEye style={{ color: "#15d112" }} />
              )}
            </span>
          </div>
<br></br>
          {/* Image Upload Input */}
          <div className="input-container" style={{ textAlign: "center" }}>
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Profile Preview"
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  marginBottom: "10px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <FaUserCircle style={{ fontSize: "80px", color: "#d3d3d3" }} />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{
                marginTop: "10px",
                display: "block",
                fontSize: "14px",
                color: "#15d112",
              }}
            />
          </div>

          {/* Submit Button */}
          <div className="input-container">
            <input defaultValue="Update Profile" type="submit" className="login-button" />
          </div>
        </form>

        {/* Link to Another Page */}
        <div className="login-redirect">
          <Link to="/profile" className="forgot-password">
            Go back to your profile
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
