import React from "react";

function Settings() {
  return (
    <div>
      <h2>Settings</h2>
      <p>Settings options will go here.</p>
    </div>
  );
}

export default Settings;
